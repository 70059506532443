<template>
  <div style="width: 100%;">
    <van-steps direction="vertical" :active="defaultIndex">
      <!-- 学院副书记 -->
      <van-step class="audit-detail" v-if="showView2">
        <div class="audit-detail-title">
          <van-row>
            <van-col span="14" :style="getSatusLabel(level2Data.status) !== '审核通过' ?'color: rgb(255, 77, 79);':'color: rgb(35, 171, 114)'">学院副书记{{getSatusLabel(level2Data.status)}}</van-col>
            <van-col span="10" style=" font-weight: bold;text-align: right;">审批人:{{level2Data.approverName}}</van-col>
          </van-row>
        </div>
        <div class="audit-detail-div">
          <div>
            <van-icon name="tosend" size="14" /> 审核时间:{{level2Data.createTime}}
          </div>
          <div style="margin-top:4px;display: -webkit-inline-box;">
            <div style="width:80px;">
              <van-icon name="comment-o" size="14" /> 审批意见:
            </div>
            <div style="width:75%;">{{level2Data.opinion}}</div>
          </div>
        </div>
      </van-step>

      <!-- 学生处 -->
      <van-step class="audit-detail" v-if="showView3">
        <div class="audit-detail-title">
          <van-row>
            <van-col span="14" :style="getSatusLabel(level3Data.status) !== '审核通过' ?'color: rgb(255, 77, 79);':'color: rgb(35, 171, 114)'">综测管理员{{getSatusLabel(level3Data.status)}}</van-col>
            <van-col span="10" style=" font-weight: bold;text-align: right;">审批人:{{level3Data.approverName}}</van-col>
          </van-row>
        </div>
        <div class="audit-detail-div">
          <div>
            <van-icon name="tosend" size="14" /> 审核时间:{{level3Data.createTime}}
          </div>
          <div style="margin-top:4px;display: -webkit-inline-box;">
            <div style="width:80px;">
              <van-icon name="comment-o" size="14" /> 审批意见:
            </div>
            <div style="width:75%;">{{level3Data.opinion}}</div>
          </div>
        </div>
      </van-step>
    </van-steps>
    <div class="audit-detail-null" v-if="!showView2 && !showView3  "> 暂无审核信息</div>
  </div>
</template>

<script>
import { listGetOne } from "@/api/common/approval";
export default {
  props: {
    appParams: {
      type: Object,
    },
  },
  data() {
    return {
      defaultIndex: 0,
      level2: [3, 201, 208],
      level3: [9, 701, 708],
      level2Data: {},
      level3Data: {},
      showView2: false,
      showView3: false,
    };
  },
  created() {
    if (
      this.appParams.coreKey &&
      this.appParams.coreCode &&
      this.appParams.mkCode &&
      this.appParams.status
    ) {
      this.init(
        this.appParams.coreKey,
        this.appParams.mkCode,
        this.appParams.coreCode,
        this.appParams.status
      );
    }
  },
  methods: {
    init(coreKey, mkCode, coreCode, spzt) {
      let status = parseInt(spzt)
      if (this.level2.indexOf(status) > -1) {
        // 院系
        listGetOne({
          coreKey: coreKey,
          mkCode: mkCode,
          coreCode: coreCode,
          field3: "2",
        }).then((res) => {
          this.level2Data = res;
        });
        this.defaultIndex = 0;
        this.showView2 = true;
        this.showView3 = false;
      } else if (this.level3.indexOf(status) > -1) {
        // 学生处
        listGetOne({
          coreKey: coreKey,
          mkCode: mkCode,
          coreCode: coreCode,
          field3: "2",
        }).then((res) => {
          this.level2Data = res;
          this.showView2 = true;
        });

        listGetOne({
          coreKey: coreKey,
          mkCode: mkCode,
          coreCode: coreCode,
          field3: "7",
        }).then((res) => {
          if (!res) {
            this.defaultIndex = 0;
          } else {
            this.level3Data = res;
            this.showView3 = true;
            this.defaultIndex = 1;
          }
        });

      }

    },
    getStatusType(status) {
      if (status === 8) {
        return "info";
      }
      if (status === 9) return "error";
      if (status === 1) return "success";
      return "info";
    },
    getSatusLabel(key) {
      if (!key) {
        return "待审核";
      }
      if (key === 8) {
        return "审核驳回";
      } else if (key === 9) {
        return "审核失败";
      } else {
        return "审核通过";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.audit-detail-null {
  padding: 17px;
  color: rgba(136, 136, 136, 1);
  font-size: 15px;
}
.audit-detail {
  &-div {
    margin: 12px 0 4px 0;
    border-radius: 6px;
    padding: 12px;
    line-height: 27px;
    font-size: 14px;
    color: rgb(136, 136, 136);
    background-color: rgba(35, 171, 114, 0.08);
  }
  &-title {
    width: 100%;
    color: rgba(51, 51, 51, 1);
    line-height: 20px;
    font-size: 15px;
  }
}

::v-deep .van-step--vertical:not(:last-child)::after {
  border-bottom-width: 0px;
}
::v-deep .van-step__title {
  padding-right: 20px;
}
</style>
